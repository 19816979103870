import React from "react";
import { Container, Flex, Box, Heading, css } from "theme-ui";
import ContentText from "@solid-ui-components/ContentText";
import Reveal from "@solid-ui-components/Reveal";
import StackTitle from "@solid-ui-components/StackTitle";
import FlexImage from "@solid-ui-components/FlexImage";
import FlexContent from "@solid-ui-components/FlexContent";
import FlexOverlapFade from "@solid-ui-components/FlexOverlapFade";
import ContentImages from "@solid-ui-components/ContentImages";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import getImage from "@solid-ui-components/utils/getImage";
import DotPic from "../../assets/dotPic.png";

const styles = {
  listItem: {
    alignItems: `stretch`,
    justifyContent: `flex-start`,
  },
  line: {
    position: `absolute`,
    bottom: `10px`,
    left: `50%`,
    width: `1px`,
    height: (t) => `calc(100% - ${t.sizes.icon.md + 20}px)`,
    transform: `translateX(-50%)`,
    zIndex: 1,
    bg: `omega`,
  },
  number: {
    size: `icon.md`,
    textAlign: `center`,
    p: 2,
    mb: 0,
  },
};

const FeaturesWithPhotoBlock07 = ({
  content: { kicker, title, text_1, text_2, images, collection },
  reverse,
}) => (
  <Container sx={{ position: `relative` }}>
    <StackTitle kicker={kicker} title={title} />
    <Flex
      sx={{
        alignItems: [null, `center`],
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          reverse ? `row-reverse` : `row`,
        ],
        mx: [null, null, null, -4],
      }}
    >
      <FlexImage reverse={reverse}>
        <ContentImages content={{ images }} reverse={reverse} />
      </FlexImage>
      <FlexContent reverse={reverse}>
        <StackTitle lines={[text_1, text_2]} align={["center", "left"]} />
        <Reveal effect={reverse ? "fadeInRight" : "fadeInLeft"} duration={1.5}>
          {collection?.map(({ title, description }, index) => (
            <Flex key={`item-${index}`} sx={styles.listItem}>
              <Box sx={{ position: `relative`, flexShrink: 0, mr: 4 }}>
                <Heading variant="h4" as="div" color="white" sx={styles.number}>
                  <img src={DotPic} alt="dot" />
                </Heading>
              </Box>
              <Box>
                <ContentText content={title} variant="h5" mb="2" />
                <ContentText
                  content={description}
                  sx={{ maxWidth: 400 }}
                  mb="4"
                />
              </Box>
            </Flex>
          ))}
        </Reveal>
      </FlexContent>
    </Flex>
    <FlexOverlapFade direction={reverse ? "ltr" : "rtl"} />
  </Container>
);

export default WithDefaultContent(FeaturesWithPhotoBlock07);
