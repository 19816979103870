import React from 'react'
import { Box } from 'theme-ui'

const FlexOverlapFade = ({ direction }) => (
  <Box
    sx={{
      display: [`none`, `block`, null, `none`],
      position: `absolute`,
      top: 0,
      left: 0,
      zIndex: -1,
      size: `full`,
      backgroundImage: t =>
        `linear-gradient(
          ${direction === 'ltr' ? '90deg' : '-90deg'},
          transparent 0%,
          ${t.colors.background} 40%,
          transparent 100%
        )`
    }}
  />
)

export default FlexOverlapFade

FlexOverlapFade.defaultProps = {
  direction: 'ltr'
}
